import React, {Fragment} from 'react';
import { useStaticQuery, graphql } from "gatsby"
import BlogHeading from '../blog-heading'
import Blog from '../../../components/blog/layout-one'
import Pagination from '../../../components/pagination'
import {SectionWrap, BlogInner} from './blog-area.stc'
 
const BlogArea = () => {
    const blogQueryData = useStaticQuery(graphql `
        query BlogDataQuery {
          allDatoCmsBlog(limit: 4) {
                totalCount
                edges {
                  node {
                      id
                      title
                      author
                      slug
                      desc
                      date(formatString: "Do MMMM, YYYY" locale:"tr")
                      image {
                        fluid(maxWidth: 600, maxHeight: 630) {
                            ...GatsbyDatoCmsFluid_noBase64
                          }
                      }
                  }
                }
            }
        }
    `);
    
    const blogs = blogQueryData.allDatoCmsBlog.edges;
    const {totalCount} = blogQueryData.allDatoCmsBlog;
    const postsPerPage = 4;
    const numberOfPages = Math.ceil(totalCount/postsPerPage);
    return (
      <Fragment>
        <SectionWrap>
            <BlogHeading/>
            <BlogInner>
                <div className="col-4 offset-1">
                    <div className="row">
                        {blogs.map((blog, i) => (
                            <div className="col-2 blog-wrap" key={`blog-${i}`}>
                                <Blog
                                    title={blog.node.title}
                                    date={blog.node.date}
                                    author={blog.node.author}
                                    id={blog.node.id}
                                    path={blog.node.slug}
                                    excerpt={blog.node.desc}
                                    image={blog.node.image.fluid}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </BlogInner>
        </SectionWrap>
        <Pagination
            currentPage={1}
            numberOfPages={numberOfPages}
        />
      </Fragment>
    )
}

export default BlogArea
import React from "react"
import SEO from '../components/PageSEO';
import Layout from '../containers/layout/layout'
import Banner from '../containers/blog/banner'
import Blog from '../containers/blog/blog-area'

export default () => (
    <Layout>
        <SEO title=" Mimarlık Blog | Mimar | Tasarım "
            description="Furkan Acar Mimarlık, Tasarım ve Proje Geliştiricisi bilgilendirici yazıları." />
        <Banner />
        <Blog />
    </Layout>
)
